import React, { Fragment } from 'react'
import {Link} from 'gatsby'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
} from '@heroicons/react/outline'
import { StaticImage } from "gatsby-plugin-image"

export default function Header() {
  return (
    <Popover className="relative z-50">
      <div className="flex justify-between items-end px-4 py-6 sm:px-6 xl:px-10 sm:justify-start sm:space-x-10">
        <div>
          <Link to="/" className="flex sm:pb-3">
            <span className="sr-only">Sara Fashionwear Logo</span>
            <StaticImage
                src="../images/logo_dark.png"
                alt="Sara Fashionwear Logo"
                placeholder="none"
                height={18}
            />
          </Link>
        </div>
        <div className="-mr-2 -my-2 sm:hidden">
          <Popover.Button className="p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-900 focus:outline-none">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-end sm:justify-between">
          <Popover.Group as="nav" className="flex space-x-10 items-end h-full pb-3 pl-2">
            <Link to="/about" className="text-base leading-none font-medium text-gray-700 hover:text-gray-900 uppercase">
              About
            </Link>
            <Link to="/products" className="text-base leading-none font-medium text-gray-700 hover:text-gray-900 uppercase">
              Products
            </Link>
            <Link to="/sourcing" className="text-base leading-none font-medium text-gray-700 hover:text-gray-900 uppercase">
              Sourcing
            </Link>
            <Link to="/compliance" className="text-base leading-none font-medium text-gray-700 hover:text-gray-900 uppercase">
              Compliance
            </Link>
          </Popover.Group>
          <div className="flex items-center md:ml-12">
            <Link
              to="/contact"
              className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-sm shadow-sm text-base font-medium text-white bg-dark-700 hover:bg-dark-900"
            >
              Get in touch
            </Link>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right sm:hidden">
          <div className="rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="py-6 px-5">
              <div className="grid grid-rows-2 gap-4">
                <Link to="/" className="text-base font-medium text-gray-900 hover:text-gray-700 uppercase">
                  Home
                </Link>
                <Link to="/about" className="text-base font-medium text-gray-900 hover:text-gray-700 uppercase">
                  About
                </Link>
                <Link to="/products" className="text-base font-medium text-gray-900 hover:text-gray-700 uppercase">
                  Products
                </Link>
                <Link to="/sourcing" className="text-base font-medium text-gray-900 hover:text-gray-700 uppercase">
                  Sourcing
                </Link>
                <Link to="/compliance" className="text-base font-medium text-gray-900 hover:text-gray-700 uppercase">
                  Compliance
                </Link>
              </div>
              <div className="mt-6">
                <Link
                  to="/contact"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-sm shadow-sm text-base font-medium text-white bg-dark-500 hover:bg-dark-700"
                >
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}