import React from 'react';
import { Link } from 'gatsby';

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Products', href: '/products' },
    { name: 'Sourcing', href: '/sourcing' },
    { name: 'Compliance', href: '/compliance' },
    { name: 'Terms', href: '/terms' },
    { name: 'Privacy', href: '/privacy' },
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/69508045/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

export default function Footer() {
  return (
    <footer className="" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className='flex flex-wrap justify-left w-full pb-8'>
              {navigation.main.map(el => <Link to={el.href} key={el.name}><h3 className="pr-10 py-2 text-base text-gray-700 hover:text-gray-900 tracking-wider uppercase">{el.name}</h3></Link>)}
            </div>
        <div className="border-t border-gray-200 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <h3 className="text-sm font-semibold text-gray-700 tracking-wider uppercase">
              Want to partner up? 
            </h3>
            <p className="mt-2 text-base text-gray-700">
              Reach out for top quality products for your fashion brand.
            </p>
          </div>
          <div className="mt-4 sm:flex sm:max-w-md lg:mt-0">
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <Link to='/contact' ><button
                type="submit"
                className="w-full bg-dark-500 border border-transparent rounded-sm py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-dark-700 focus:outline-none"
              >
                Get in touch
              </button></Link>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} target='_blank' rel="noreferrer" className="text-gray-700 hover:text-gray-900">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-700 md:mt-0 md:order-1">
            &copy; 2022 Sara Fashionwear Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}