import * as React from "react"
import PropTypes from "prop-types"
 
import Header from './header'; 
import Footer from "./footer";

 const Layout = ({ children }) => {
 
   return (
     <>
       <div className="bg-light-100 min-h-screen" >
           <Header/>
           {children}
           <Footer/>
       </div>
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout